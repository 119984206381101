import React from 'react';
import Layout from './components/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6 text-white">Ochrana osobných údajov</h2>
        <div className="text-gray-400 space-y-6">
          <h3 className="text-xl font-bold">1. Zber údajov</h3>
          <p>
            Naša stránka eErotika.sk zbiera len tie údaje, ktoré sú nevyhnutné na správne fungovanie webovej stránky 
            a na vedenie používateľských účtov. Patria sem údaje ako meno, e-mailová adresa, a ďalšie informácie potrebné 
            na registráciu a používanie našich služieb.
          </p>

          <h3 className="text-xl font-bold">2. Uchovávanie údajov</h3>
          <p>
            Vaše osobné údaje sú uchovávané len počas aktívneho používania vášho účtu. Ak sa rozhodnete zrušiť váš účet, 
            všetky vaše údaje budú automaticky vymazané z našich systémov.
          </p>

          <h3 className="text-xl font-bold">3. Práva používateľov</h3>
          <p>
            Ak máte záujem vedieť, aké údaje o vás uchovávame, alebo chcete svoje údaje upraviť či vymazať, 
            kontaktujte nás prosím na <a href="mailto:info@eerotika.sk" className="text-red-600">info@eerotika.sk</a>.
          </p>

          <h3 className="text-xl font-bold">4. Bezpečnosť údajov</h3>
          <p>
            Osobné údaje používateľov uchovávame bezpečne a prijímame všetky primerané opatrenia na ochranu vašich údajov 
            pred neoprávneným prístupom, zneužitím alebo zverejnením.
          </p>

          <h3 className="text-xl font-bold">5. Zmeny v politike ochrany údajov</h3>
          <p>
            eErotika.sk si vyhradzuje právo zmeniť tieto zásady ochrany osobných údajov. Všetky zmeny budú zverejnené na tejto stránke, 
            a používateľ bude informovaný o týchto zmenách pri ďalšom použití webu.
          </p>

          <h3 className="text-xl font-bold">6. Kontakt</h3>
          <p>
            V prípade akýchkoľvek otázok týkajúcich sa ochrany osobných údajov nás môžete kontaktovať na 
            <a href="mailto:info@eerotika.sk" className="text-red-600"> info@eerotika.sk</a>.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
