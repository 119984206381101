import React, { createContext, useState, useContext } from "react";
import PocketBase from "pocketbase";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";

// Initialize PocketBase with your server URL
const pb = new PocketBase("https://eerotika.sk");

// Create a context for authentication
const AuthContext = createContext();

// AuthProvider component to wrap your app
export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(pb.authStore.isValid);
  const [user, setUser] = useState(pb.authStore.model);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const signUp = async (username, email, password, passwordConfirm) => {
    try {
      const record = await pb.collection("users").create({
        username: username,
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
      });

      setMessage(`Používateľ ${record.username} bol úspešne vytvorený.`);
      await logIn(email, password);
      return record;
    } catch (error) {
      setMessage("Chyba pri vytváraní účtu. Skúste znova.");
      throw error;
    }
  };

  const logIn = async (identifier, password) => {
    try {
      const authData = await pb.collection("users").authWithPassword(identifier, password);
      setLoggedIn(true);

      const userProfile = await pb.collection("users").getOne(authData.record.id);
      setUser(userProfile);
      setMessage("Prihlásenie úspešné.");
      navigate("/user/dashboard");
      return authData;
    } catch (error) {
      setMessage("Chyba pri prihlasovaní. Skontrolujte svoje údaje.");
      throw error;
    }
  };

  const logOut = () => {
    pb.authStore.clear();
    setLoggedIn(false);
    setUser(null);
    setMessage("Používateľ bol odhlásený.");
    navigate("/auth/login");
  };

  return (
    <AuthContext.Provider value={{ loggedIn, user, signUp, logIn, logOut, message }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};

// SignUpForm component for creating an account
export const SignUpForm = () => {
  const { signUp, message } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      await signUp(username, email, password, passwordConfirm);
      setUsername("");
      setEmail("");
      setPassword("");
      setPasswordConfirm("");
      navigate("/user/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="max-w-lg mx-auto bg-gray-900 p-10 rounded-2xl shadow-lg">
        <h2 className="text-4xl font-extrabold text-center text-red-500 mb-8">Vytvoriť účet</h2>
        <input
          type="text"
          placeholder="Používateľské meno"
          className="w-full p-4 mb-5 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          className="w-full p-4 mb-5 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Heslo"
          className="w-full p-4 mb-5 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Potvrdiť heslo"
          className="w-full p-4 mb-8 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <button
          onClick={handleSignUp}
          className="w-full bg-gradient-to-r from-red-500 to-pink-600 text-white py-4 rounded-lg hover:from-red-600 hover:to-pink-700 shadow-lg transition duration-300"
        >
          Vytvoriť účet
        </button>
        {message && <p className="text-red-500 mt-4 text-center">{message}</p>}
      </div>
    </Layout>
  );
};

// LoginForm component for logging in
export const LoginForm = () => {
  const { logIn, message } = useAuth();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogIn = async () => {
    try {
      await logIn(identifier, password);
      navigate("/user/dashboard");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div className="max-w-lg mx-auto bg-gray-900 p-10 rounded-2xl shadow-lg">
        <h2 className="text-4xl font-extrabold text-center text-red-500 mb-8">Prihlásenie</h2>
        <input
          type="text"
          placeholder="Používateľské meno alebo Email"
          className="w-full p-4 mb-5 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
        />
        <input
          type="password"
          placeholder="Heslo"
          className="w-full p-4 mb-8 bg-gray-800 text-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-red-600"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          onClick={handleLogIn}
          className="w-full bg-gradient-to-r from-red-500 to-pink-600 text-white py-4 rounded-lg hover:from-red-600 hover:to-pink-700 shadow-lg transition duration-300"
        >
          Prihlásiť sa
        </button>
        {message && <p className="text-red-500 mt-4 text-center">{message}</p>}
      </div>
    </Layout>
  );
};
