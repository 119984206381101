import React, { useState, useEffect } from "react";
import { useAuth } from "./Auth";
import { useNavigate } from "react-router-dom";
import PocketBase from "pocketbase";
import Layout from "./components/Layout";
import Loading from "./components/Loading"; // Import loading component

// Initialize PocketBase with your server URL
const pb = new PocketBase("https://eerotika.sk");

const Settings = () => {
  const { user: authUser, logOut } = useAuth();
  const [username, setUsername] = useState(authUser?.username || "");
  const [iban, setIban] = useState(authUser?.iban || "");
  const [description, setDescription] = useState(authUser?.description || "");
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(""); // Start with an empty string for avatar URL
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordSectionOpen, setPasswordSectionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  // Load the avatar URL when the component mounts
  useEffect(() => {
    if (authUser?.avatar) {
      setAvatarUrl(`https://eerotika.sk/api/files/users/${authUser.id}/${authUser.avatar}`);
    } else {
      setAvatarUrl("https://via.placeholder.com/100"); // Fallback placeholder
    }
  }, [authUser]);

  // Handle form submission for updating user info
  const handleUpdateSettings = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0); // Scroll to the top of the page when the route changes
    setError(""); // Clear previous errors
    setSuccessMessage(""); // Clear previous success message
    setIsLoading(true); // Start loading

    if (password && password !== passwordConfirm) {
      setError("Heslá sa nezhodujú.");
      setIsLoading(false); // Stop loading on error
      return;
    }

    try {
      const formData = new FormData();
      formData.append("username", username);
      formData.append("iban", iban);
      formData.append("description", description);

      if (oldPassword && password) {
        formData.append("oldPassword", oldPassword);
        formData.append("password", password);
        formData.append("passwordConfirm", passwordConfirm);
      }

      if (avatar) {
        formData.append("avatar", avatar);
      }

      const updatedUser = await pb.collection("users").update(authUser.id, formData);
      setSuccessMessage("Nastavenia boli úspešne aktualizované.");

      // Update avatar if uploaded
      if (avatar) {
        const newAvatarUrl = `https://eerotika.sk/api/files/users/${authUser.id}/${updatedUser.avatar}`;
        setAvatarUrl(newAvatarUrl);
        setAvatar(null); // Clear the avatar file input
      }
    } catch (error) {
      setError("Chyba pri aktualizácii nastavení.");
      console.error(error);
    } finally {
      setIsLoading(false); // Stop loading when done
    }
  };

  // Handle account deletion
  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      "Naozaj chcete vymazať svoj účet? Tento krok je nevratný."
    );
    if (confirmDelete) {
      try {
        setIsLoading(true); // Start loading
        await pb.collection("users").delete(authUser.id);
        logOut(); // Log out the user after account deletion
        navigate("/"); // Redirect to home after account deletion
      } catch (error) {
        setError("Chyba pri mazaní účtu.");
        console.error(error);
      } finally {
        setIsLoading(false); // Stop loading when done
      }
    }
  };

  // Toggle password section visibility
  const togglePasswordSection = () => {
    setPasswordSectionOpen(!passwordSectionOpen);
  };

  return (
    <Layout>
      <Loading isLoading={isLoading} /> {/* Loading spinner */}

      <div className="max-w-5xl mx-auto py-16 px-4">
        <h1 className="text-5xl font-bold text-red-600 mb-8">Nastavenia účtu</h1>

        {/* Profile Card */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-md flex items-center space-x-6 mb-8">
          <img
            src={avatarUrl}
            alt="Avatar"
            className="w-24 h-24 rounded-full object-cover"
          />
          <div>
            <h2 className="text-2xl text-white font-bold">{username}</h2>
            <p className="text-gray-400">{description}</p>
          </div>
        </div>

        {/* Error/Success Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

        {/* Settings Form */}
        <form onSubmit={handleUpdateSettings} className="space-y-6">
          {/* Username */}
          <div>
            <label className="block text-white mb-2">Používateľské meno</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>

          {/* IBAN */}
          <div>
            <label className="block text-white mb-2">IBAN</label>
            <input
              type="text"
              value={iban}
              onChange={(e) => setIban(e.target.value)}
              className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>

          {/* Description */}
          <div>
            <label className="block text-white mb-2">Popis profilu</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
              rows="4"
            />
          </div>

          {/* Avatar */}
          <div>
            <label className="block text-white mb-2">Profilová fotka</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setAvatar(e.target.files[0])}
              className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none"
            />
            {avatar && (
              <p className="text-gray-400 mt-2">Zvolený súbor: {avatar.name}</p>
            )}
          </div>

          {/* Password Section */}
          <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <button
              type="button"
              onClick={togglePasswordSection}
              className="w-full text-left text-white font-semibold flex justify-between items-center"
            >
              <span>Zmeniť heslo</span>
              {passwordSectionOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </button>

            {/* Collapsible Password Fields */}
            {passwordSectionOpen && (
              <div className="mt-4 space-y-4">
                <div>
                  <label className="block text-white mb-2">Staré heslo</label>
                  <input
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
                  />
                </div>
                <div>
                  <label className="block text-white mb-2">Nové heslo</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
                  />
                </div>
                <div>
                  <label className="block text-white mb-2">Potvrdiť nové heslo</label>
                  <input
                    type="password"
                    value={passwordConfirm}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    className="w-full p-3 rounded-md bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-red-600"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 rounded-md transition duration-300"
          >
            Aktualizovať nastavenia
          </button>
        </form>

        {/* Delete Account */}
        <div className="mt-8">
          <button
            onClick={handleDeleteAccount}
            className="w-full bg-red-700 hover:bg-red-800 text-white font-bold py-3 rounded-md transition duration-300"
          >
            Vymazať účet
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
