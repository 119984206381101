import React from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';

const NotFound = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center min-h-screen text-center">
        <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
        <h2 className="text-2xl font-semibold text-white mb-6">Oops! Stránka, ktorú hľadáte, neexistuje.</h2>
        <p className="text-gray-400 mb-8">
          Možno bola odstránená alebo ste zadali nesprávnu URL adresu.
        </p>
        <Link
          to="/"
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
        >
          Návrat na hlavnú stránku
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
