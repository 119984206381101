// CartContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';  // Pridáme axios na API volania
import Loading from './Loading';  // Importuj Loading komponent

// Create a Cart Context
export const CartContext = createContext();

// CartProvider component to wrap the application
export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load cart from localStorage when the app starts
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);  // Pridáme stav pre načítavanie

  // Save cart to localStorage whenever cart changes
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Calculate the total price whenever the cart changes
  useEffect(() => {
    const total = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  }, [cart]);

  // Add to cart function
  const addToCart = (product) => {
    const existingCart = [...cart];
    const productExists = existingCart.find(item => item.id === product.id);

    if (productExists) {
      // Increase quantity if product already exists
      productExists.quantity += 1;
      setCart(existingCart);
    } else {
      // Add new product with quantity = 1
      const newProduct = { ...product, quantity: 1 };
      setCart([...cart, newProduct]);
    }
  };

  // Update quantity and remove if quantity is 0
  const updateQuantity = (productId, quantity) => {
    const updatedCart = cart.map(item =>
      item.id === productId ? { ...item, quantity } : item
    ).filter(item => item.quantity > 0); // Remove items with quantity <= 0
    setCart(updatedCart);
  };

  // Create payment function - komunikuje s Flask API
  const createPayment = async () => {
    try {
      setIsLoading(true);  // Zapne loading pred vytvorením platby
      const response = await axios.post('https://api.eerotika.sk/create-payment', {
        amount: totalPrice,
        cart_items: cart
      });

      const { url } = response.data;

      // Presmerovanie na Stripe platobný link
      window.location.href = url;

      // Vymazanie košíka po úspešnom presmerovaní
      setCart([]);
    } catch (error) {
      console.error('Chyba pri vytváraní platby:', error);
    } finally {
      setIsLoading(false);  // Vypne loading po skončení platby
    }
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, updateQuantity, totalPrice, createPayment }}>
      {children}
      <Loading isLoading={isLoading} />  {/* Zobrazí loading komponent */}
    </CartContext.Provider>
  );
};
