import React from 'react';
import Layout from './components/Layout';

const Terms = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-6 text-white">Obchodné podmienky</h2>
        <div className="text-gray-400 space-y-6">
          <h3 className="text-xl font-bold">1. Všeobecné informácie</h3>
          <p>
            Webová stránka eErotika.sk funguje ako trhovisko, kde môžu používatelia nakupovať a predávať produkty. 
            Nie sme priamo zodpovední za transakcie medzi kupujúcimi a predávajúcimi a nenesieme žiadnu zodpovednosť za 
            vady produktov, podvody alebo iné nevhodné správanie používateľov. V prípade problémov nás však môžete 
            kontaktovať na emailovej adrese <a href="mailto:info@eerotika.sk" className="text-red-600">info@eerotika.sk</a>.
          </p>

          <h3 className="text-xl font-bold">2. Služby trhoviska</h3>
          <p>
            eErotika.sk je len sprostredkovateľom medzi používateľmi a nepredáva ani nevlastní žiadne produkty. 
            Všetky ceny uvedené na eErotika.sk zahŕňajú 20% DPH. Z každej úspešnej transakcie si účtujeme 10% províziu, 
            ktorá bude odrátaná pred vyplatením predajcovi.
          </p>

          <h3 className="text-xl font-bold">3. Zodpovednosť používateľov</h3>
          <p>
            <strong>Predajcovia:</strong> Musia zabezpečiť, aby produkty uvedené na predaj boli v súlade so všetkými 
            platnými zákonmi a boli správne opísané. Predajcovia sú tiež zodpovední za vybavovanie vrátení a reklamácií.
            <br />
            <strong>Kupujúci:</strong> Pred kúpou sú povinní dôkladne skontrolovať všetky informácie o produkte. 
            Akékoľvek spory by mali byť riešené priamo medzi predajcom a kupujúcim.
          </p>

          <h3 className="text-xl font-bold">4. Platby a vyplácanie</h3>
          <p>
            Platby predajcom sa vykonávajú každý pondelok, avšak v niektorých prípadoch môže byť doba vyplácania predĺžená až na 14 dní. 
            Vyplácaná suma bude vypočítaná po odrátaní našej 10% provízie a príslušnej DPH.
          </p>

          <h3 className="text-xl font-bold">5. Zodpovednosť a riešenie sporov</h3>
          <p>
            eErotika.sk nenesie žiadnu zodpovednosť za konanie používateľov vrátane podvodných aktivít. V prípade sporov sa 
            môžete obrátiť na našu podporu prostredníctvom emailu <a href="mailto:info@eerotika.sk" className="text-red-600">info@eerotika.sk</a>.
          </p>

          <h3 className="text-xl font-bold">6. Zakázané činnosti</h3>
          <p>
            Je zakázané podvádzať, uverejňovať nelegálny obsah alebo sa vydávať za iných používateľov. 
            Používatelia, ktorí porušia tieto podmienky, môžu byť dočasne alebo natrvalo zablokovaní.
          </p>

          <h3 className="text-xl font-bold">7. Zmeny podmienok</h3>
          <p>
            eErotika.sk si vyhradzuje právo tieto podmienky kedykoľvek zmeniť. Používatelia budú o zmenách informovaní na webovej stránke, 
            pričom ďalšie používanie platformy znamená súhlas s aktualizovanými podmienkami.
          </p>

          <h3 className="text-xl font-bold">8. Rozhodné právo</h3>
          <p>
            Tieto podmienky sa riadia zákonmi Slovenskej republiky. Všetky spory budú riešené v súlade so slovenským právom.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
