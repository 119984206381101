import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PocketBase from 'pocketbase';
import { CheckCircle, ThumbsUp, ThumbsDown } from 'react-feather'; // Icons for like and unlike
import Layout from './components/Layout';
import { useNavigate } from "react-router-dom";
import NotFound from './components/NotFound';
import ProfilePayments from './components/ProfilePayments';

// Initialize PocketBase
const pb = new PocketBase('https://eerotika.sk');

const Profile = () => {
  const { id } = useParams(); // Get user ID from the URL
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState([]); // Store user's products
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null); // Store the current logged-in user
  const [hasLiked, setHasLiked] = useState(false); // Track if the current user has liked this profile
  const [likeRecordId, setLikeRecordId] = useState(null); // Store the ID of the like record (for unliking)
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Fetch logged-in user (if any)
        const authUser = pb.authStore.model;
        setCurrentUser(authUser);

        // Fetch the profile user (the one being viewed)
        const userResult = await pb.collection('users').getOne(id);
        setUser(userResult);

        // Fetch user products by owner ID
        const productsResult = await pb.collection('user_products').getFullList({
          filter: `owner="${id}"`, // Filter products by owner (user ID)
        });
        setProducts(productsResult);

        // Check if the current user has already liked this profile
        if (authUser) {
          const likeResult = await pb.collection('likes').getFullList({
            filter: `from="${authUser.id}" && to="${id}"`, // Check if the like exists
          });

          if (likeResult.length > 0) {
            setHasLiked(true);
            setLikeRecordId(likeResult[0].id); // Store the like record ID for unliking
          }
        }
      } catch (error) {
        console.error('Error fetching user or products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  // Function to handle liking a user
  const handleLike = async () => {
    if (!currentUser) return;

    try {
      // Create the like record
      const data = {
        from: currentUser.id, // The user giving the like
        to: user.id // The user receiving the like
      };
      const newLike = await pb.collection('likes').create(data);

      // Increment the likes field for the user
      await pb.collection('users').update(user.id, {
        likes: user.likes + 1
      });

      // Update the local state with the new number of likes
      setUser((prevUser) => ({
        ...prevUser,
        likes: prevUser.likes + 1
      }));

      setHasLiked(true);
      setLikeRecordId(newLike.id); // Store the ID for unliking
    } catch (error) {
      console.error('Error liking profile:', error);
    }
  };

  // Function to handle unliking a user
  const handleUnlike = async () => {
    if (!currentUser || !likeRecordId) return;

    try {
      // Delete the like record
      await pb.collection('likes').delete(likeRecordId);

      // Decrement the likes field for the user
      await pb.collection('users').update(user.id, {
        likes: user.likes - 1
      });

      // Update the local state with the new number of likes
      setUser((prevUser) => ({
        ...prevUser,
        likes: prevUser.likes - 1
      }));

      setHasLiked(false);
      setLikeRecordId(null); // Reset the like record ID
    } catch (error) {
      console.error('Error unliking profile:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <NotFound />;
  }

  return (
    <Layout>
      {/* User Profile Section */}
      <section className="max-w-7xl mx-auto py-16 px-4">
        <div className="bg-gray-800 p-8 rounded-lg text-white shadow-lg">
          <div className="flex items-center mb-6 space-x-6">
            {/* User avatar */}
            <img
              src={user.avatar ? `https://eerotika.sk/api/files/_pb_users_auth_/${user.id}/${user.avatar}` : 'https://via.placeholder.com/150'}
              alt={user.username}
              className="w-28 h-28 object-cover rounded-full border-4 border-gray-600"
            />
            <div>
              {/* Username and verified status */}
              <h1 className="text-4xl font-bold flex items-center">
                {user.username}
                {user.verified && (
                  <CheckCircle className="ml-2 text-green-500" title="Verified" />
                )}
              </h1>
              {/* Number of likes */}
              <p className="text-gray-400 mt-2">Likes: {user.likes}</p>

              {/* Like/Unlike button (visible only if the user is logged in and not viewing their own profile) */}
              {currentUser && currentUser.id !== user.id && (
                <button
                  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center"
                  onClick={hasLiked ? handleUnlike : handleLike}
                >
                  {hasLiked ? (
                    <>
                      <ThumbsDown className="mr-2" /> Unlike this profile
                    </>
                  ) : (
                    <>
                      <ThumbsUp className="mr-2" /> Like this profile
                    </>
                  )}
                </button>
              )}
            </div>
          </div>

          {/* User description */}
          <p className="mt-4 text-gray-300">{user.description}</p>
        </div>
      </section>

      <section id="user-products" className="max-w-7xl mx-auto py-16 px-4">
  <h2 className="text-4xl font-bold text-center text-white mb-12">Produkty používateľa</h2>
  {products.length > 0 ? (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      {products.map((product) => (
        <div
          key={product.id}
          className="bg-gray-800 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
        >
          {product.virtual ? (
            <div className="relative w-full h-56 bg-gray-900 flex justify-center items-center mb-4 rounded-lg">
              <div className="absolute inset-0 bg-black opacity-50 rounded-lg"></div>
              <img
                src="https://cdn.jsdelivr.net/npm/bootstrap-icons/icons/lock-fill.svg"
                alt="Locked"
                className="w-20 h-20 text-red-600 z-10"
              />
              <p className="text-gray-300 text-center mt-2 z-10 font-semibold">
                Tento produkt je uzamknutý. Dostupný po kúpe.
              </p>
            </div>
          ) : (
            <img
              src={product.photo ? `https://eerotika.sk/api/files/${product.collectionId}/${product.id}/${product.photo}` : 'https://via.placeholder.com/150'}
              alt={product.name}
              className="w-full h-56 object-cover mb-4 rounded-lg transition-transform duration-300 hover:scale-110"
            />
          )}
          <h3 className="text-xl font-bold text-white mb-2">{product.name}</h3>
          <p className="text-gray-400 text-sm mb-4">{product.description}</p>
          <div className="flex justify-between items-center">
            <p className="text-red-500 font-bold text-lg">
              {new Intl.NumberFormat('sk-SK', {
                style: 'currency',
                currency: 'EUR',
              }).format(product.price)}
            </p>
            <ProfilePayments profile_id={id} product_id={product.id} price={product.price} />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <p className="text-gray-400 text-center">Tento používateľ nemá žiadne produkty.</p>
  )}
</section>

    </Layout>
  );
};

export default Profile;
