import React from 'react';

const AgeVerificationPopup = ({ onAccept, onReject }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center z-50 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
      {/* Overlay - dimmed background */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      {/* Popup content */}
      <div className="relative bg-white p-10 rounded-xl shadow-2xl max-w-md w-full text-center z-50 transform transition-transform duration-500 scale-100 hover:scale-105">
        <h2 className="text-3xl font-extrabold mb-5 text-gray-900">Overenie veku</h2>
        <p className="mb-8 text-lg text-gray-700">Máte aspoň 18 rokov?</p>
        <div className="flex justify-around space-x-4">
          <button
            onClick={onAccept}
            className="bg-green-500 hover:bg-green-600 text-white px-8 py-3 rounded-full shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
          >
            Áno
          </button>
          <button
            onClick={onReject}
            className="bg-red-500 hover:bg-red-600 text-white px-8 py-3 rounded-full shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:scale-105"
          >
            Nie
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationPopup;
