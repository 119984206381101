import React, { useState } from 'react';
import axios from 'axios';
import Loading from './Loading'; // Predpokladáme, že Loading komponent je už vytvorený

const ProfilePayments = ({ profile_id, product_id, price }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async () => {
    setIsLoading(true); // Spustíme načítavanie

    try {
      // Odoslanie požiadavky na API
      const response = await axios.post('https://api.eerotika.sk/create-custom-payment', {
        seller_id: profile_id,  // Seller ID (profile ID)
        product_id,  // Product ID
        price,  // Cena
      });

      const { url } = response.data;
      
      // Krátka pauza pred presmerovaním (1ms)
      setTimeout(() => {
        setIsLoading(false); // Zastavíme načítavanie
        window.location.href = url; // Presmerovanie na platobný odkaz
      }, 1);
    } catch (error) {
      console.error('Chyba pri vytváraní platby:', error);
      setIsLoading(false); // Zastavíme načítavanie pri chybe
    }
  };

  return (
    <div>
      <button
        onClick={handlePayment}
        className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition duration-300 ease-in-out shadow-lg font-semibold w-full"
        disabled={isLoading}  // Tlačidlo sa deaktivuje počas načítavania
      >
        Kúpiť teraz
      </button>
      <Loading isLoading={isLoading} /> {/* Načítavanie */}
    </div>
  );
};

export default ProfilePayments;
